.post-back-link {
  display: flex;
  padding: 15px;
  height: 50px;
  font-size: 1.2em;
}

.post-container {
  display: flex;
  flex-direction: row;
  justify-content: top;
  align-items: top;

  .post-box {
    display: inline-block;
    position: relative;
    width: 600px;
    margin: 35px auto;
    background-color: white;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.13), 0px 2px 4px rgba(0, 0, 0, 0.2);
    padding: 16px 23px;

    .headline {
      font-size: 16px;
      line-height: 19px;
      opacity: 0.54;
    }

    .post-content {
      display: flex;
      flex-direction: row;
      margin-top: 15px;
      align-content: stretch;

      .avatar {
        margin: 0 10px 0 0;
        margin-bottom: 0px;
        width: 36px;
        height: 36px;
      }

      .username {
        font-size: 16px;
        line-height: 19px;
      }

      .timestamp {
        font-size: 12px;
        color: #9e9ea6;
      }

      .celebration {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        line-height: 16px;
        color: #757575;

        .celebration-gif {
          padding-top: 5px;
          max-width: 400px;
        }
      }
    }

    .gallery-image {
      display: block;
      max-width: 170px;
      max-height: 170px;
      width: auto;
      height: auto;
    }

    .back-to-slack {
      font-size: 1.2em;
      margin-top: 15px;
      float: right;

      a {
        display: flex;
      }
    }

    .delete-image-btn {
      background: transparent;
      color: #f48fb1;
      border: none;
      display: flex;
      line-height: unset;
      outline: none;
      margin: auto;
      margin-top: 5px;
      cursor: pointer;

      .MuiSvgIcon-root {
        font-size: 1.5em;
      }
    }

    .btn-snap-photo {
      font-size: 1.1em;
      margin-top: 10px;
      color: #0645ad;
      border: none;
      outline: none;
      background-color: transparent;
      text-decoration: underline;
      cursor: pointer;
      display: flex;
      align-items: center;

      .MuiSvgIcon-root {
        margin-right: 2px;
      }
    }

    .MuiGrid-root {
      margin-top: 10px;
    }
  }
}
