.dropzone {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 170px;
  height: 170px;
  cursor: pointer;

  &.draggingOver {
    border: 2px dashed;
    background-color: #fff;
  }

  .file-input {
    display: none;
  }

  .upload-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;

    p {
      font-size: 12px;
    }
  }
}
