.webcam-app {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;

  .webcam {
    display: block;
    position: relative;
    width: auto;
    height: 100vh;
    z-index: 99;
    pointer-events: none;
    margin: auto;
  }

  .canvas {
    background-color: transparent;
    position: absolute;
    width: auto;
    height: 100vh;
    z-index: 999;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .controls {
    color: #fff;
    position: sticky;
    bottom: 50px;
    display: flex;
    z-index: 9999;
    justify-content: center;
  }
}
